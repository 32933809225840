<template>
	<BaseEditControls
		:block-id="blockId"
		:block-ref="blockRef"
		:current-tab="currentTab"
	>
		<template #additional-buttons>
			<ZyroButton
				theme="editor"
				class="manage-button"
				@click="openGalleryManager"
			>
				{{ $t('builder.editGallery.manageButton') }}
			</ZyroButton>
			<ZyroButton
				class="settings-button"
				theme="editor"
				icon="settings"
				:title="$t('builder.editGallery.title')"
				@click="openEditPopup"
			/>
			<Portal to="app">
				<AssetManagerv2
					v-if="showGalleryManager"
					is-gallery
					:gallery-id="currentElementId"
					@select-image="addImagesToGallery([$event])"
					@select-images="addImagesToGallery($event)"
					@close="showGalleryManager = false"
				/>
			</Portal>
		</template>
		<ZyroPopupCard
			type="editor"
			:title="$t('builder.editGallery.popupTitle')"
			@change="currentTab = $event"
			@close="$emit('close')"
		>
			<Component :is="currentTab.component" />
		</ZyroPopupCard>
	</BaseEditControls>
</template>

<script>
import {
	mapActions,
	mapGetters,
	mapMutations,
	mapState,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import BaseEditControls from '@/components/builder-controls/BaseEditControls.vue';
import { useEditGridGallery } from '@/components/builder-controls/edit-gallery/useEditGridGallery';
import AssetManagerv2 from '@/components/builder-modals/modals/AssetManagerv2.vue';
import i18n from '@/i18n/setup';

import EditGalleryMain from './EditGalleryMain.vue';

const tabs = [
	{
		title: i18n.t('common.general'),
		component: 'EditGalleryMain',
	},
];

export default {
	components: {
		BaseEditControls,
		AssetManagerv2,
		EditGalleryMain,
	},
	props: {
		blockId: {
			type: String,
			required: true,
		},
		blockRef: {
			type: HTMLElement,
			required: true,
		},
	},
	setup() {
		const { showGalleryManager } = useEditGridGallery();

		return { showGalleryManager };
	},
	data() {
		return { currentTab: this.$options.tabs[0] };
	},
	computed: {
		...mapState([
			'website',
			'currentElementId',
		]),
		...mapGetters(['currentElement']),
	},
	methods: {
		...mapActions('gui', ['setEditMode']),
		...mapMutations([
			'setElementData',
			'setCurrentBlock',
		]),
		openGalleryManager() {
			EventLogApi.logEvent({ eventName: 'builder.gallery_element.manage' });
			this.setCurrentBlock({
				blockId: this.blockId,
				blockRef: this.blockRef,
			});
			this.showGalleryManager = true;
		},
		addImagesToGallery(images) {
			const oldImages = this.currentElement.images;
			const newImages = images.map(({ url }) => url);

			this.setElementData({
				data: {
					images: [
						...oldImages,
						...newImages,
					],
				},
				skipHistory: false,
			});
		},
		openEditPopup() {
			// Current editor is based on currentBlock, so we need to set it, comment from EditBlockPopup
			this.setCurrentBlock({
				blockId: this.blockId,
				blockRef: this.blockRef,
			});
			this.setEditMode(true);
		},
	},
	tabs,
};
</script>

<style lang="scss" scoped>
.manage-button,
.settings-button {
	// TODO: BaseEditControls should probably handle this, but it would need lots of refactoring
	margin-right: 8px;
}
</style>
