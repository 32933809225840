<template>
	<div>
		<ZyroLabel class="layout-label">
			{{ $t('builder.editGallery.layout') }}
		</ZyroLabel>
		<ZyroIconSelect
			:current.sync="layout"
			:options="$options.LAYOUT_OPTIONS"
		/>
		<ZyroFieldRange
			:label="$t('builder.editCommon.itemsPerRow')"
			units=""
			:value="columnCount"
			min="1"
			max="16"
			@input="columnCount = $event.target.value"
		/>
		<ZyroFieldRange
			:label="$t('builder.editCommon.gapBetweenPhotos')"
			:value="columnGap"
			min="0"
			max="64"
			step="2"
			@input="columnGap = $event.target.value"
		/>
		<ZyroDropdown
			:options="IMAGE_CLICK_ACTIONS"
			:current.sync="imageClickAction"
			:label="$t('builder.imageClickAction.title')"
			button-size="large"
			v-on="$listeners"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import {
	IMAGE_CLICK_ACTION_LIGHTBOX,
	IMAGE_CLICK_ACTION_LIGHTBOX_TITLE,
	IMAGE_CLICK_ACTION_NONE,
	IMAGE_CLICK_ACTION_NONE_TITLE,
} from '@/components/builder-controls/constants';
import { useBuilderStyles } from '@/components/builder-controls/useBuilderStyles';
import i18n from '@/i18n/setup';
import { cloneDeep } from '@/utils/object';

const IMAGE_CLICK_ACTIONS = [
	{
		title: IMAGE_CLICK_ACTION_NONE_TITLE,
		value: IMAGE_CLICK_ACTION_NONE,
	},
	{
		title: IMAGE_CLICK_ACTION_LIGHTBOX_TITLE,
		value: IMAGE_CLICK_ACTION_LIGHTBOX,
	},
];

const LAYOUT_OPTIONS = {
	grid: {
		name: i18n.t('builder.editGallery.grid'),
		icon: 'grid',
	},
	masonry: {
		name: i18n.t('builder.editGallery.masonry'),
		icon: 'masonry',
	},
};

export default {
	LAYOUT_OPTIONS,
	setup() {
		const {
			getStyleValue,
			getStyleKey,
		} = useBuilderStyles();

		const getImageClickAction = (action) => IMAGE_CLICK_ACTIONS
			.find((actionSet) => actionSet.value === action);

		return {
			getStyleValue,
			getStyleKey,
			IMAGE_CLICK_ACTIONS,
			getImageClickAction,
		};
	},
	data() {
		return {
			id: '',
			currentElementBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapState('gui', ['isMobileView']),
		...mapGetters(['currentElement']),
		currentElementStyles() {
			return this.currentElement.settings.styles;
		},
		layout: {
			get() {
				return this.currentElement.settings.layout;
			},
			set(layout) {
				EventLogApi.logEvent({
					eventName: 'builder.image_gallery.set_layout',
					eventProperties: { layout },
				});
				this.setElementData({ data: { settings: { layout } } });
			},
		},
		columnCount: {
			get() {
				return Number.parseInt(this.getStyleValue('column-count', this.currentElementStyles, false), 10);
			},
			set(newValue) {
				const key = this.getStyleKey('column-count', false);

				this.setElementData({ data: { settings: { styles: { [key]: `${newValue}` } } } });
			},
		},
		columnGap: {
			get() {
				return Number.parseInt(this.getStyleValue('column-gap', this.currentElementStyles, false), 10);
			},
			set(newValue) {
				const key = this.getStyleKey('column-gap', false);

				this.setElementData({ data: { settings: { styles: { [key]: `${newValue}px` } } } });
			},
		},
		imageClickAction: {
			get() {
				return this.getImageClickAction(this.currentElement.settings.imageClickAction);
			},
			set(action) {
				this.setElementData({ data: { settings: { imageClickAction: action.value } } });
			},
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.id,
			oldData: this.currentElementBeforeEdit,
		});
	},
	methods: {
		...mapMutations([
			'setElementData',
			'pushElementDataToHistory',
		]),
	},
};
</script>

<style lang="scss" scoped>
.layout-label {
	margin-bottom: 8px;
}
</style>
